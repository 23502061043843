<template>
  <div class="ai-create">
    <div v-show="currentType === 2" class="ai-preview-container">
      <div id="scrollBox" class="ai-create-preview">
        <ul ref="box" id="wordsList" class="text-box">
          <li
            v-for="(item, index) in lyricsList"
            :key="index"
            :class="{ 'null-str': item === '' }"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="preview-close finish-close" @click="currentType = 1">
        <div class="c-btn c-left"></div>
        <div class="c-btn c-right"></div>
      </div>
    </div>
    <div v-if="this.$route.query.source !== 'ppt'" class="route-btn">
      <p @click="goIndexPage">首页</p>
      <p>&nbsp;/&nbsp;</p>
      <p>创作</p>
    </div>
    <div v-show="currentType === 1" class="ai-create-container">
      <h1
        v-html="
          $t('create.title', {
            style: `<span>${currentTitle}</span>`
          })
        "
      ></h1>
      <i ref="leftArrow" class="left-arrow" @click="goPrev"></i>
      <div
        class="ai-words"
        :style="{
          backgroundImage: `url(${require('@/assets/images/word_bg.png')})`
        }"
      >
        <div class="ai-words-left">
          <h2 class="ai-words-title">{{ $t('create.wordsTitle') }}</h2>
          <agm-scroll
            class="ai-words-items"
            :height="273 * rt"
            :scroll-bar-width="6"
            bar-bg-color="#aebdd8"
          >
            <ul class="ai-words--items">
              <li
                v-for="(item, index) in lyricsData.structures"
                :ref="`structures-${index}`"
                :key="index"
                :class="[
                  'ai-words--item',
                  { active: !item.disable },
                  { isFinish: item.isFinish }
                ]"
                @click="handleLyrics(index)"
              >
                <img
                  :src="require('/src/assets/images/right_icon.png')"
                  alt=""
                  :style="{ width: '0.11rem' }"
                />
                <span>{{ item.name }}<i v-show="item.isFinish"></i></span>
                <em class="open-tag" @click="handleOpen(item, index)"></em>
              </li>
            </ul>
          </agm-scroll>
          <div class="ai-words-footer">
            <button
              @click="handleAddItem"
              :style="{
                backgroundImage: `url(${require('@/assets/images/btn1_bg.png')})`
              }"
            >
              {{ $t('create.addBtn') }}
            </button>
          </div>
        </div>
        <div class="ai-words-right">
          <agm-scroll
            ref="aiCreateEl"
            :height="410 * rt"
            :scroll-bar-width="8 * rt"
            bar-bg-color="#aebdd8"
          >
            <HaiCreate
              v-for="(item, index) in currentStructures"
              v-model="item.content"
              :key="index"
              :tips="item.placeholder"
              :idx="index"
              :id="id"
              :show-magic="!(index === 0)"
              :is-first="index === 0"
              :finish-len="prevFinishLen"
              :can-input="item.canInput"
              :high-idx="highTextIdx"
              :finish-sentence="finishSentences"
              :is-clude-null="includeNull"
              @on-ai-select="handleAiInput"
              @on-ai-magic="handleAiMagic"
              @on-high-magic="handleHighMagic"
              @on-input="handleItemInput"
              @on-first-show="handleFirstShow"
              @on-operation="handleTypeClick"
            >
            </HaiCreate>
          </agm-scroll>
          <div v-show="isFirstShow" class="prompt-toast">
            {{ $t('create.tips') }}
          </div>
          <div class="ai-footer">
            <p>
              {{ '小提示' }}：
              {{ curTips }}
            </p>
            <!-- @likg/Tips → 如果来源是PPT则隐藏提交按钮 -->
            <div
              v-if="this.$route.query.source !== 'ppt'"
              class="ai-footer-btns"
            >
              <button
                :class="{ disable: allSentences.length === 0 || includeNull }"
                @click="handlePreview(3)"
              >
                {{ $t('create.preview') }}
              </button>

              <button
                @click="confirm = true"
                :class="{ disable: allSentences.length === 0 || includeNull }"
              >
                提交
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--修改歌词结构-->
      <ul
        :style="listsStyle"
        class="ai-words--item-types"
        v-click-outside="onClickOutside"
      >
        <li
          v-for="theme in themes"
          :key="theme.id"
          :class="['ai-words--item-type', { active: theme.active }]"
          @click.stop="handleType(theme)"
        >
          <em></em> <span>{{ theme.name }}</span>
        </li>
      </ul>
      <!-- <HaiFooter /> -->
    </div>
    <!--预览-->
    <div v-show="currentType === 3" class="preview-dialog">
      <div class="content">
        <ul class="text-box preview-box">
          <li
            class="preview-li"
            v-for="(item, index) in lyricsList"
            :key="index"
            :class="{ 'null-str': item === '' }"
          >
            {{ item }}
          </li>
        </ul>

        <div class="preview-close" @click="currentType = 1">
          <img
            :src="require('@/assets/images/words_close.png')"
            alt=""
            style="width: 0.32rem; height: 0.32rem"
          />
        </div>
      </div>
    </div>
    <!--删除-->
    <div v-show="delObj.show" class="del-confirm">
      <div class="warp">
        <div class="head">{{ $t('create.dialog.title') }}</div>
        <p class="f-content">{{ $t('create.dialog.content') }}</p>
        <div class="f-foot">
          <button class="f-btn-cancel" @click="handleCancelDel">
            {{ $t('create.dialog.cancel') }}
          </button>
          <button class="f-btn-confirm" @click="handleConfirmDel">
            {{ $t('create.dialog.confirm') }}
          </button>
        </div>
      </div>
    </div>
    <!--提交作品-->
    <transition name="fade">
      <div v-show="confirm" class="confirm-modal">
        <div class="warp">
          <div class="header">
            提交作品
            <div
              @click="
                confirm = false
                uploadObj.work_name = ''
              "
            ></div>
          </div>
          <div class="content">
            <div class="item">
              <span> <font color="red">*</font>作品名称： </span>
              <input type="text" class="input" v-model="uploadObj.work_name" />
            </div>
            <div class="item">
              <span>作品封面：</span>
              <img
                :src="
                  uploadObj.picture
                    ? uploadObj.picture
                    : require('../assets/images/upload.png')
                "
                alt=""
                width="280px"
                height="210px"
                @click="$refs.file.click()"
              />
              <input
                type="file"
                hidden
                ref="file"
                accept=".png,.jpg,.jpeg"
                @change="upload"
              />
            </div>
            <p class="tips">
              请上传.jpg.jpeg.png且大小不超过5m图片，建议155 * 155。
            </p>
          </div>
          <div class="f-foot">
            <button
              class="f-btn-cancel"
              @click="
                confirm = false
                uploadObj.work_name = ''
              "
            >
              取消
            </button>
            <button class="f-btn-confirm" @click="handleUpload">提交</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import HaiCreate from '@/components/HaiCreate'

// import HaiFooter from "@/components/HaiFooter";
import { uploadImg, GetDirty, create_lyrics_work } from '@/api/create.js'

// const GLOBAL_TIPS = [
//   '主歌是流行歌曲中的主体，主要描述人、地点、故事，通常四句左右。',
//   '过渡可以称为副歌前的导句，一般二句到四句组成，最好不要超过主歌或副歌。',
//   '大部分歌曲的流行句式，记忆点都设置在副歌部分。',
//   '桥段是为了改变和扭转歌曲中情绪和气氛，使听觉有新的冲击的段落。'
// ]
// const LYRIC_MAP = ['主歌', '过渡', '副歌', '桥段']

export default {
  name: 'AdvanceCreate',
  components: {
    HaiCreate
    // HaiFooter,
  },
  data() {
    return {
      uploadObj: {
        work_name: '',
        picture: ''
      },
      confirm: false,
      timer: null,
      lyricsData: {
        length: 3,
        maxLength: 8,
        structures: []
      },
      listsStyle: {
        display: 'none'
      },
      selItem: {
        name: '',
        index: -1
      }, // 当前点击歌词结构项选中项(下拉框)
      currentTitle: '',
      currentType: 1, // 1 编辑状态， 2 预览状态
      lyricsList: [],
      delObj: {
        show: false,
        idx: -1
      }, // 删除确认弹窗显示
      highTextIdx: {
        idx: -1,
        timestamp: 0
      },
      isFirstShow: false,
      ftSize: 0,
      id: 0
    }
  },
  computed: {
    rt() {
      return (this.ftSize / 100 + 0.01).toFixed(2) / 1
    },
    // 当前选中的歌词结构的lists
    currentStructures() {
      const structs = this.lyricsData.structures.filter(
        item => item.active === true
      )
      return structs.length > 0 ? structs[0].contents : []
    },
    // 当前选中的歌词结构
    curLyricsIdx() {
      return this.lyricsData.structures.findIndex(item => item.active === true)
    },
    currentStructuresLen() {
      return this.currentStructures.length
    },
    themes() {
      let lists = [
        { id: 0, name: this.$t('createTips.mainSong'), active: false, type: 0 },
        {
          id: 1,
          name: this.$t('createTips.transition'),
          active: false,
          type: 0
        },
        { id: 2, name: this.$t('createTips.refrain'), active: false, type: 0 },
        {
          id: 3,
          name: this.$t('createTips.bridgeSection'),
          active: false,
          type: 0
        }
      ]
      if (this.lyricsData.length > 1) {
        lists.push({
          id: 5,
          name: this.$t('createTips.del'),
          active: false,
          type: 1
        })
      }
      lists.forEach(item => (item.active = item.name === this.selItem.name))
      return lists
    },
    // 已完成的句子数
    finishSentenceLen() {
      return this.lyricsData.structures.filter(item => item.isFinish).length
    },
    // 不能添加
    unAddItem() {
      return this.lyricsData.length === this.lyricsData.maxLength
    },
    allSentences() {
      return this.lyricsData.structures.reduce((total, currentValue) => {
        return total.concat(
          currentValue.contents.reduce((arrs, item) => {
            arrs.push(item.content)
            return arrs
          }, [])
        )
      }, [])
    },
    currentSentences() {
      const structs = this.lyricsData.structures.filter(
        item => item.active === true
      )
      return structs.reduce((total, currentValue) => {
        return total.concat(
          currentValue.contents.reduce((arrs, item) => {
            arrs.push(item.content)
            return arrs
          }, [])
        )
      }, [])
    },
    includeNull() {
      let arrs = JSON.parse(JSON.stringify(this.allSentences))
      let i = arrs.length - 1
      while (i > 0) {
        if (arrs[i].trim() === '') {
          arrs.splice(i, 1)
          i = arrs.length - 1
        } else {
          i = 0
        }
      }
      return arrs.includes('')
    },
    finishSentences() {
      return this.lyricsData.structures.reduce((total, currentValue) => {
        return total.concat(
          currentValue.contents.reduce((arrs, item) => {
            if (item.content !== '') {
              arrs.push(item.content)
            }
            return arrs
          }, [])
        )
      }, [])
    },
    prevFinishLen() {
      return this.lyricsData.structures.reduce((total, currentVal, index) => {
        if (index < this.curLyricsIdx) {
          total += currentVal.contents.length
        }
        return total
      }, 0)
    },
    curTips() {
      const curName = this.lyricsData.structures[this.curLyricsIdx]
        ? this.lyricsData.structures[this.curLyricsIdx].name
        : ''
      const idx = this.$t('lyricMap').findIndex(item => item === curName) || 0
      return this.$t('globalTips[' + idx + ']')
    }
  },
  mounted() {
    // ppt过来时读取tk并保存
    const tk = this.$route.query.tk
    if (tk) {
      window.sessionStorage.setItem('tk', tk)
      console.log('tk >>>>>', tk)
    }

    const r = window.screen.availWidth / 1920
    this.$refs.leftArrow.style.Transform = 'scale(' + r + ')'

    window.addEventListener('resize', () => {
      this.ftSize = parseInt(document.documentElement.style.fontSize)
    })

    this.ftSize = parseInt(document.documentElement.style.fontSize)

    this.currentTitle = this.$route.query.title
    const style = this.$route.query.style
    this.id = this.$route.query.id
    const styleMap = {
      pop: [
        this.$t('createTips.mainSong'),
        this.$t('createTips.transition'),
        this.$t('createTips.refrain')
      ],
      folk: [
        this.$t('createTips.mainSong'),
        this.$t('createTips.mainSong'),
        this.$t('createTips.refrain')
      ],
      rock: [
        this.$t('createTips.mainSong'),
        this.$t('createTips.mainSong'),
        this.$t('createTips.refrain')
      ],
      chinese: [this.$t('createTips.mainSong'), this.$t('createTips.refrain')],
      country: [
        this.$t('createTips.mainSong'),
        this.$t('createTips.refrain'),
        this.$t('createTips.bridgeSection')
      ]
    }
    const types = styleMap[style]
    console.log('fca', types)
    const _lyricsData = {
      length: types.length,
      maxLength: 8,
      structures: []
    }
    types.forEach((item, index) => {
      const tpl = {
        name: this.$t('createTips.mainSong'),
        active: false,
        isFinish: false,
        disable: false,
        contents: [
          {
            content: '',
            placeholder: this.$t('createTips.tips'),
            isComplete: false,
            canInput: true
          },
          {
            content: '',
            placeholder: this.$t('createTips.finishPrev'),
            isComplete: false,
            canInput: false
          },
          {
            content: '',
            placeholder: this.$t('createTips.finishPrev'),
            isComplete: false,
            canInput: false
          },
          {
            content: '',
            placeholder: this.$t('createTips.finishPrev'),
            isComplete: false,
            canInput: false
          }
        ]
      }
      tpl.active = index === 0
      tpl.disable = index !== 0
      tpl.name = item
      _lyricsData.structures.push(tpl)
    })
    this.lyricsData = _lyricsData
  },
  methods: {
    async upload(e) {
      const [file] = e.target.files
      const formData = new FormData()
      formData.append('file', file)
      formData.append('name', file.name)
      console.log(sessionStorage.getItem('tk'))
      const tk = sessionStorage.getItem('tk')
      const { data } = await uploadImg(formData, {
        headers: {
          Authorization: tk ? 'Bearer ' + tk : undefined
        }
      })
      console.log(data)
      this.uploadObj.picture = data.full_path
    },
    async handleUpload() {
      if (
        !this.uploadObj.work_name.length ||
        !this.uploadObj.work_name.trim().length
      ) {
        this.$message({
          text: '作品名称必填'
        })
        return
      }
      if (!/^[\s\S]{2,8}$/i.test(this.uploadObj.work_name)) {
        this.$message({
          text: '作品名称请输入2-8个字符'
        })
        return
      }
      console.log('aasdw', this.lyricsList)
      // eslint-disable-next-line no-undef
      const res1 = await GetDirty({
        sen: this.uploadObj.work_name
      })
      if (res1.code === 200 && res1.data !== '') {
        this.$message({
          text: this.$t('作品名称存在敏感词汇')
        })
        this.$set(this.uploadObj, 'work_name', '')
        return
      }
      this.lyricsList = this.lyricsData.structures.reduce(
        (total, currentValue) => {
          // 产品需求 每个段落空一行
          return total.concat(
            currentValue.contents.reduce((arrs, item, i) => {
              if (item.content) {
                if (currentValue.contents.length == i + 1) {
                  arrs.push(item.content + ';')
                } else {
                  arrs.push(item.content + ',')
                }
              }
              return arrs
            }, [])
          )
        },
        []
      )
      const tk = sessionStorage.getItem('tk')
      create_lyrics_work(
        {
          work_name: this.uploadObj.work_name,
          picture: this.uploadObj.picture,
          lyrics_work: {
            lyrics: this.lyricsList.join(''),
            type: this.$route.query.title
          }
        },
        {
          headers: {
            Authorization: tk ? 'Bearer ' + tk : undefined
          }
        }
      ).then(res => {
        const { code, msg } = res
        this.$message(
          {
            text: code == 200 ? `提交成功` : `${msg}`
          },
          () => {
            this.confirm = false
            Object.keys(this.uploadObj).forEach(i => {
              this.uploadObj[i] = ''
            })
          }
        )
      })
    },
    handleFirstShow(val) {
      this.isFirstShow = val
    },
    // 切换歌词结构
    handleLyrics(idx) {
      // 当前点击
      if (idx === this.curLyricsIdx) {
        return
      }
      // 检查当前索引的歌词结构是否完成
      const curStusIsFinish = this.lyricsData.structures[idx].isFinish
      // const curLyricsIdxIsFinish = this.lyricsData.structures[this.curLyricsIdx]
      //   .isFinish

      const prevIsFinish =
        idx > 0 ? this.lyricsData.structures[idx - 1].isFinish : true

      if (prevIsFinish || curStusIsFinish) {
        this.setCurrentStructures(idx)
        return
      }

      if (idx > this.curLyricsIdx + 1) {
        this.$message({
          text: this.$t('createTips.order')
        })
        return
      }

      // 下一个歌词结构
      const prevStructuresIsFinish = this.lyricsData.structures[
        this.curLyricsIdx
      ].isFinish
      if (prevStructuresIsFinish) {
        this.$refs.aiCreateEl.goTop()
        this.setCurrentStructures(idx)
      } else {
        if (curStusIsFinish) {
          this.setCurrentStructures(idx)
          return
        }
        this.$message({
          text: this.$t('createTips.finishCurStru')
        })
      }
    },
    handleItemInput({ event, index }) {
      this.setLyricsData(this.curLyricsIdx, index, event.target.value)
      // this.checkAllRows()
    },
    // handleItemChange ({ event, index }) {
    //   console.log(event.target.value, index, '=========')
    // },
    // eslint-disable-next-line
    handleAiInput({ value, index, isMagic }) {
      this.setLyricsData(this.curLyricsIdx, index, value)
    },
    handleHighMagic({ index }) {
      if (index >= 1) {
        // 是押韵，前一句高亮
        this.highTextIdx = {
          idx: index - 1,
          timestamp: new Date().valueOf()
        }
      }
    },
    handleAiMagic({ value, index }) {
      const prevRowData = this.lyricsData.structures[this.curLyricsIdx]
        .contents[index].content
      const num = prevRowData.length - 1 - value.length
      const val = prevRowData.slice(0, num) + value
      this.setLyricsData(this.curLyricsIdx, index, val)
    },
    // 添加歌词结构
    handleAddItem() {
      // 超过最大限制不能添加
      if (this.unAddItem) {
        this.$message({
          text: this.$t('createTips.isMax')
        })
        return
      }

      let tpl = {
        name: this.$t('createTips.mainSong'),
        active: false,
        isFinish: false,
        disable: true,
        contents: [
          {
            content: '',
            placeholder: this.$t('createTips.tips'),
            isComplete: false,
            canInput: true
          },
          {
            content: '',
            placeholder: this.$t('createTips.finishPrev'),
            isComplete: false,
            canInput: false
          },
          {
            content: '',
            placeholder: this.$t('createTips.finishPrev'),
            isComplete: false,
            canInput: false
          },
          {
            content: '',
            placeholder: this.$t('createTips.finishPrev'),
            isComplete: false,
            canInput: false
          }
        ]
      }
      if (this.finishSentenceLen === this.lyricsData.length) {
        tpl.disable = false
      }
      this.lyricsData.length++
      this.lyricsData.structures.push(tpl)
    },
    /**
     * 打开选择操作框
     * @param item
     * @param idx
     */
    handleOpen(item, idx) {
      // 被禁用状态
      if (item.disable) return
      // 已显示
      if (!this.listsStyle.display) return

      const curRef = `structures-${idx}`
      const curDom = this.$refs[curRef][0]
      const pos = curDom.getBoundingClientRect()
      const marginTop = 4
      const fixedX = pos.x
      const fixedY = pos.y + pos.height + marginTop

      this.selItem = { name: item.name, index: idx }

      this.listsStyle = {
        position: 'fixed',
        left: fixedX + 'px',
        top: fixedY + 'px'
      }
    },
    /**
     * 项目选择
     * @param row
     */
    handleType(row) {
      if (row.type) {
        // 删除
        this.delObj = {
          show: true,
          idx: this.curLyricsIdx
        }
      } else {
        // 修改该项
        this.selItem.name = row.name
        this.lyricsData.structures[this.selItem.index].name = row.name
      }

      this.listsStyle = {
        display: 'none'
      }
    },
    handleCancelDel() {
      this.delObj = {
        show: false,
        idx: -1
      }
    },
    handleConfirmDel() {
      const tempObj = JSON.parse(JSON.stringify(this.lyricsData))
      const length = tempObj.length
      const idx = this.delObj.idx
      let newIdx = 0
      if (length <= 1) {
        this.$message({
          text: this.$t('createTips.isLess')
        })
        return
      }
      if (tempObj.structures[idx].active) {
        if (idx === 0) {
          newIdx++
        } else {
          newIdx = idx - 1
        }
      }
      tempObj.structures[newIdx].active = true
      tempObj.structures[newIdx].disable = false
      tempObj.structures.splice(idx, 1)
      tempObj.length--
      this.lyricsData = tempObj
      this.handleCancelDel()
    },
    handlePreview(type) {
      if (this.finishSentences.length === 0 || this.includeNull) return

      // -- AI作词 & AI作曲 打通
      const lyricArr = []
      this.lyricsData.structures.forEach(({ contents }) => {
        contents.forEach(({ content }) => {
          if (content) {
            lyricArr.push(content)
          }
        })
      })
      const lyricString = lyricArr.join(',')
      window.parent.postMessage(
        { type: 'JUMP_TO_COMPOSED', lyric: lyricString },
        '*'
      )

      // this.lyricsList = this.lyricsData.structures.reduce(
      //   (total, currentValue, index) => {
      //     // 产品需求 每个段落空一行
      //     if (index) {
      //       total.push('')
      //     }
      //     return total.concat(
      //       currentValue.contents.reduce(
      //         (arrs, item) => arrs.concat(item.content),
      //         []
      //       )
      //     )
      //   },
      //   []
      // )
      // this.currentType = type
      // if (type === 2) {
      //   this.timer = setInterval(this.scrollLyrics, 25)
      // }
    },
    onClickOutside(e) {
      if (!e.target.classList.contains('open-tag')) {
        this.listsStyle = {
          display: 'none'
        }
      }
    },
    handleTypeClick({ type, index }) {
      if (type === 'add') {
        // 添加上限
        if (this.currentStructuresLen === 8) {
          this.$message({
            text: this.$t('createTips.tooMuch')
          })
          return
        }

        const tpl = {
          content: '',
          placeholder: this.$t('createTips.finishPrev'),
          isComplete: false,
          canInput: false
        }
        const curRowIsComplete = this.checkRowIsComplete(index)

        if (curRowIsComplete) {
          tpl.canInput = true
          tpl.placeholder = this.$t('createTips.tips')
        }
        // 检查下一句
        if (index < this.currentStructuresLen - 1) {
          const nextRowIsComplete = this.checkRowIsComplete(index + 1)
          if (!nextRowIsComplete) {
            this.lyricsData.structures[this.curLyricsIdx].contents[
              index + 1
            ].placeholder = this.$t('createTips.finishPrev')
          }
        }
        this.lyricsData.structures[this.curLyricsIdx].contents.splice(
          index + 1,
          0,
          tpl
        )
        // 滚动到指定位置
        const containerH = 380 * this.rt
        const inputPad = 110 * this.rt
        const inputH = 50 * this.rt
        // 110 是input的间距 + 高度 和
        // 380 容器的高度
        this.$nextTick(() => {
          const scrollTop = this.$refs.aiCreateEl.getScrollTop()
          const top = (index + 1) * inputPad
          const isInView = top - scrollTop < containerH
          if (isInView) {
            // 在视图中
          } else {
            const showScrollDis = top - containerH + inputH
            this.$refs.aiCreateEl.goAnyWhere(showScrollDis)
          }
        })
        // 遍历其他结构
        this.checkAllRows()
      } else {
        if (this.currentStructuresLen === 4) {
          this.$message({
            text: this.$t('createTips.tooLess')
          })
          return
        }
        // 修改下一行的状态
        if (index <= this.currentStructuresLen - 2) {
          this.lyricsData.structures[this.curLyricsIdx].contents[
            index + 1
          ].placeholder = this.lyricsData.structures[
            this.curLyricsIdx
          ].contents[index].placeholder
          this.lyricsData.structures[this.curLyricsIdx].contents[
            index + 1
          ].canInput = this.lyricsData.structures[this.curLyricsIdx].contents[
            index
          ].canInput
        }
        // 删除当前行
        this.lyricsData.structures[this.curLyricsIdx].contents.splice(index, 1)
      }
      // 更新当前歌词结构是否完成
      this.checkStructuresIsFinish(this.curLyricsIdx)
    },
    checkAllRows() {
      let unFinishCount = 0
      this.lyricsData.structures[this.curLyricsIdx].contents.forEach(item => {
        if (!item.isComplete) {
          unFinishCount++
        }
        if (unFinishCount <= 1) {
          item.canInput = true
          item.placeholder = this.$t('createTips.tips')
        } else {
          item.canInput = false
          item.placeholder = this.$t('createTips.finishPrev')
        }
      })
    },
    // 设置输入框的值
    setLyricsData(pIndex, index, val) {
      const len = this.lyricsData.structures[pIndex].contents.length
      this.lyricsData.structures[pIndex].contents[index].content = val
      // 修改当前输入框的输入状态
      if (val) {
        this.lyricsData.structures[pIndex].contents[index].isComplete = true
        // 存在下一个输入框(下一个输入框可以输入)
        if (index >= 0 && index < len - 1) {
          this.checkAllRows()
        }
      } else {
        // 解决删除前面某一行时
        this.lyricsData.structures[pIndex].contents[index].isComplete = false
        if (index >= 0 && index < len - 1) {
          this.checkAllRows()
        }
      }
      // 更新当前歌词结构是否完成
      this.checkStructuresIsFinish(pIndex)
    },
    // 检查当前行是否完成
    checkStructuresIsFinish(index) {
      const unCompletes = this.lyricsData.structures[index].contents.filter(
        item => item.isComplete === false
      )
      this.lyricsData.structures[index].isFinish = unCompletes.length === 0
    },
    /**
     * 检查当前行是否完成
     * @param index (当前行索引)
     * @returns {boolean}
     */
    checkRowIsComplete(index) {
      return this.lyricsData.structures[this.curLyricsIdx].contents[index]
        .isComplete
    },
    /**
     * 切换到前1|| n个歌词结构
     * @param idx
     */
    setCurrentStructures(idx) {
      const currentIdx = this.curLyricsIdx
      this.lyricsData.structures[idx].disable = false
      this.lyricsData.structures[idx].active = true
      this.lyricsData.structures[currentIdx].disable = true
      this.lyricsData.structures[currentIdx].active = false
    },
    goPrev() {
      this.$router.go(-1)
    },
    scrollLyrics() {
      // currentType 2 完成， 3 预览
      let idx = this.currentType === 2 ? 0 : 1
      let area = document.querySelectorAll('.ai-create-preview')[idx]
      let $box = document.querySelectorAll('.text-box')[idx]
      if (!area || !$box) return false
      if (area.scrollHeight - area.scrollTop === area.offsetHeight) {
        clearInterval(this.timer)
      } else {
        area.scrollTop++
      }
    },
    goIndexPage() {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.ai-create {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.ai-create-container {
  overflow: hidden;
  width: 100%;
  height: 100%;

  > h1 {
    margin: 1.07rem auto 0.51rem;

    font-family: SourceHanSansCN-Normal;
    text-align: center;
    // width: 3.65rem;
    height: 0.5rem;
    font-size: 0.54rem;
    font-weight: 400;
    letter-spacing: 20px;
    color: #3a577d;
    line-height: 0.5rem;
    white-space: nowrap;

    > span {
      color: #66e3eb !important;
    }
  }

  .left-arrow {
    background: url('~@/assets/images/left.png') no-repeat center;
    width: 40px;
    height: 40px;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    left: 98px;
    opacity: 0.5;
    top: 46.9%;

    &:hover {
      opacity: 1;
    }
  }
}

.ai-words {
  margin: 0 auto;
  height: 6.59rem;
  display: flex;
  display: -webkit-flex;
  width: 13rem;
  background-size: 100% 100%;

  &-left {
    padding-top: 101px;
    padding-left: 49px;
    width: 3.24rem;
    height: 100%;
    // background: rgba(104, 231, 237, 0.16);
    border-radius: 0.08rem;
    position: relative;
  }

  &-right {
    padding-top: 101px;

    margin-left: 0.5rem;
    width: 7.54rem;
    position: relative;
  }

  &-title {
    width: 1.9rem;
    height: 0.5rem;

    font-size: 37px;
    color: #333333;
    text-align: center;
    font-weight: 600;
  }

  &-footer {
    height: 0.57rem;
    border-top: 0.01rem solid rgba(104, 231, 237, 0.16);
    // padding: 0.14rem 0;
    position: absolute;
    left: 0;
    bottom: 92px;
    right: 0;

    > button {
      width: 1.84rem;
      height: 0.43rem;
      text-align: center;
      font-size: 0.22rem;
      color: #fff;
      line-height: 0.43rem;
      background-size: 100% 100%;
      background-color: transparent;
      outline: none;
      border: none;
      cursor: pointer;
      display: block;
      margin: 0 auto;
    }
  }
}

.ai-words--items {
  padding: 0.16rem;

  .ai-words--item {
    width: 2.45rem;
    height: 0.49rem;
    /*background-color: rgba(255, 255, 255, 0.84);*/
    // background-color: #193a50;
    padding: 0 0.4rem;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 0.14rem;
    }

    i {
      display: inline-block;
      width: 0.18rem;
      height: 0.18rem;
      background: url('~@/assets/images/nike.png') no-repeat center / contain;
      margin-left: 0.13rem;
    }

    &.isFinish,
    &.active {
      background-image: url('~@/assets/images/active_bg.png');
      background-size: 100% 100%;

      > span {
        color: #3a577d;
        font-weight: 600;
        opacity: 1;
      }

      > em {
        background: url('~@/assets/images/ell.png') no-repeat center / contain;
      }
    }

    > span {
      display: block;
      height: 0.28rem;

      font-size: 25px;

      font-weight: 400;

      color: #939ba5;
    }

    > em {
      width: 0.18rem;
      height: 0.18rem;
      background: url('~@/assets/images/more2.png') no-repeat center / contain;
    }
  }
}

.ai-words--item-types {
  z-index: 20;
  width: 1.84rem;
  height: 1.77rem;

  background: #edf2f6;
  padding: 0.17rem 0 0.17rem 0.13rem;

  .ai-words--item-type {
    height: 0.22rem;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 0.1rem;
    }

    > em {
      width: 0.11rem;
      height: 0.08rem;
      background: url('~@/assets/images/type-sel.png') no-repeat center /
        contain;
      opacity: 0;
      margin-right: 0.08rem;
    }

    > span {
      height: 0.22rem;
      font-size: 0.16rem;
      font-weight: 400;
      color: rgba(58, 87, 125, 0.5);
      line-height: 0.22rem;
      display: block;
    }

    &.active {
      span {
        color: #3a577d;
      }

      > em {
        opacity: 1;
      }
    }
  }
}

.prompt-toast {
  height: 0.53rem;
  width: 1.65rem;
  line-height: 0.43rem;
  font-size: 0.16rem;
  text-align: center;
  background: url('~@/assets/images/first-show.png') no-repeat center / contain;
  color: #fff;
  position: absolute;
  top: 50px;
  left: 5.4rem;
  margin-left: -0.745rem;
}

.ai-footer {
  padding-top: 30px;
  // width: 9.92rem;
  // padding-left: 2.4rem;
  // padding-right: 1.52rem;
  margin: 0 auto;

  p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  &-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    // display: -webkit-flex;
    // display: flex;
    // align-items: center;
    // width: 2.68rem;
    text-align: center;
    // justify-content: space-between;
    margin: 0 auto;

    > button {
      cursor: pointer;
      border: none;
      outline: none;
      width: 1.84rem;
      height: 0.44rem;
      color: #ffffff;
      font-size: 0.22rem;
      font-weight: 600;
      line-height: 0.44rem;
      background: url('~@/assets/images/btn1_bg.png') no-repeat center / contain;

      &.disable {
        opacity: 0.7;
        cursor: not-allowed;
        pointer-events: none;
      }

      &:first-child {
        margin-right: 97px;
      }
    }
  }
}

.ai-preview-container {
  width: 6.2rem;
  margin: 0 auto;
  position: relative;
}

.ai-create-preview {
  position: relative;
  width: 6.2rem;
  margin: 0 auto;
  height: 5.8rem;
  text-align: center;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /*火狐下隐藏滚动条*/
  overflow: -moz-scrollbars-none;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  /* Firefox 4 */
  -webkit-transition: all 0.5s;
  /* Safari 和 Chrome */
  -o-transition: all 0.5s;
  /* Opera */
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.6) 15%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 0.6) 85%,
    rgba(255, 255, 255, 0) 100%
  );
  mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.6) 15%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 0.6) 85%,
    rgba(255, 255, 255, 0) 100%
  );

  &::-webkit-scrollbar {
    display: none;
  }

  .close-btn {
    position: absolute;
    right: 0.32rem;
    top: 0.2rem;
    width: 0.4rem;
    height: 0.4rem;
    font-size: 0.2rem;
    cursor: pointer;
    color: #9a9a9a;
    font-weight: bold;
    background-color: #fff;
    transition: all 0.5s linear;

    &:hover {
      transform: rotate(180deg);
      transition: all 0.5s linear;
    }
  }

  ul {
    width: 100%;
    position: relative;
    top: 1rem;
    overflow: hidden;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    /* Firefox 4 */
    -webkit-transition: all 0.5s;
    /* Safari 和 Chrome */
    -o-transition: all 0.5s;

    li {
      display: block;
      overflow: hidden;
      font-size: 0.3rem;
      line-height: 0.8rem;

      color: #3a577d;

      &.null-str {
        line-height: 0.3rem;
        height: 0.3rem;
      }
    }
  }
}

.preview-box {
  width: 6.2rem;
  height: 3.97rem;
  overflow-y: auto !important;
  top: 0 !important;
  // padding-top: 1rem;
}

.preview-content {
  height: 3.97rem;
}

.route-btn {
  position: absolute;
  left: 40px;
  top: 40px;
  text-align: left;
  display: flex;
  // width: 2.64rem;
  // height: 0.56rem;
  overflow: hidden;
  color: #3a577d;
  font-size: 0;

  p {
    // width: 264px;
    height: 0.56rem;
    cursor: pointer;
    text-align: center;
    font-size: 0.28rem;
    line-height: 0.6rem;

    // color: #ffffff;
    &:last-child {
      opacity: 0.5;
    }
  }
}

.confirm-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.7);

  .warp {
    width: 8rem;
    // height: 5.81rem;
    padding-bottom: 20px;
    background: #fff;
    // background-image: url('~@/assets/images/del_bg.png');
    background-size: contain;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .header {
      height: 80px;
      position: relative;
      font-size: 28px;
      font-family: Source Han Serif CN;
      font-weight: bold;
      color: #3f5b80;
      line-height: 80px;
      text-align: center;
      background: #e5ecf1;

      div {
        position: absolute;
        background: url('../assets/images/close.png') no-repeat center/100%;
        right: 31px;
        width: 24px;
        height: 24px;
        top: 28px;
        cursor: pointer;
      }
    }

    .content {
      padding: 48px 0 30px 83px;

      span {
        font-size: 20px;
        font-family: Source Han Serif CN;
        font-weight: bold;
        color: #141b24;
      }

      .item {
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        position: relative;

        .input {
          width: 510px;
          height: 48px;
          background-color: #e1e4ea;
          border: none;
          padding-left: 20px;
        }

        img {
          cursor: pointer;
        }
      }

      .tips {
        font-size: 16px;
        color: red;
        margin-top: 6px;
        padding-left: 100px;
      }
    }

    .f-foot {
      display: flex;
      justify-content: flex-end;
      padding-right: 36px;

      button {
        cursor: pointer;
        border: none;
        outline: none;
        width: 1.84rem;
        height: 0.44rem;
        color: #ffffff;
        font-size: 0.22rem;
        font-weight: 600;
        line-height: 0.44rem;
        background: url('~@/assets/images/btn1_bg.png') no-repeat center /
          contain;

        &.disable {
          opacity: 0.7;
          cursor: not-allowed;
        }

        &:first-child {
          margin-right: 15px;
        }
      }
    }
  }
}

.del-confirm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.7);

  .warp {
    width: 7.6rem;
    height: 2.92rem;
    background-image: url('~@/assets/images/del_bg.png');
    background-size: contain;
    border-radius: 0.08rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .head {
      padding-top: 24px;
      width: 100%;
      border-radius: 0.08rem 0.08rem 0 0;
      font-size: 0.43rem;
      font-weight: 700;
      text-align: center;

      color: #3b587d;
      line-height: 0.48rem;
    }

    .f-content {
      padding-left: 51px;
      margin-top: 58px;
      // width: 4rem;
      // height: 0.28rem;
      font-size: 0.2rem;
      font-weight: 600;

      color: #3a577d;
      // margin: 0.3rem auto 0.49rem auto;
    }

    .f-foot {
      display: -webkit-flex;
      display: flex;
      justify-content: flex-end;
      margin-top: 81px;
      padding-right: 34px;

      .f-btn-confirm {
        background: transparent;
        width: 131px;
        height: 0.31rem;
        font-size: 0.2rem;
        font-weight: 500;
        color: #fff;
        line-height: 0.31rem;
        margin-left: 0.32rem;
        border: none;
        outline: none;
        cursor: pointer;
        background-image: url('~@/assets/images/btn1_bg.png');
        background-size: contain;
      }

      .f-btn-cancel {
        width: 131px;

        height: 0.31rem;
        font-size: 0.2rem;
        font-weight: 400;
        color: #fff;

        line-height: 0.31rem;
        border: none;
        outline: none;
        cursor: pointer;
        background-color: transparent;
        background-image: url('~@/assets/images/btn1_bg.png');
        background-size: contain;
      }
    }
  }
}

.preview-container {
  height: 3.97rem;
  width: 6.2rem;
}

.preview-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.7);

  .content {
    width: 763px;
    height: 524px;
    background-image: url('~@/assets/images/words_bg.png');
    background-size: contain;
    border-radius: 0.08rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.73rem 1.09rem;
  }

  .preview-li {
    height: 0.37rem;
    font-size: 0.26rem;
    font-weight: 500;

    color: #3a577d;
    line-height: 0.37rem;
    text-align: center;
    margin-bottom: 0.35rem;
    margin-left: -40px;

    &.null-str {
      height: 0.1rem;
      line-height: 0.1rem;
    }
  }
}

.preview-close {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  width: 0.32rem;
  height: 0.32rem;
  overflow: hidden;
  cursor: pointer;
}
</style>
